@use "@scss/global.scss" as *;

@mixin skeleton-content-item {
  border-radius: 20px;
  background: $white-1;
}

.staking-card-skeleton {
  position: relative;
  max-width: $app-container;
  padding: 30px 40px;
  width: 100%;
  border-radius: 20px;
  background: #efeef2;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gradient-8;
    animation: skeleton-loading 1.2s infinite ease-in-out;
  }

  &--base {
    height: 230px;
  }

  &--withTimeline {
    height: 290px;
  }

  .card-content {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    z-index: 1;
    height: 100%;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .header-card-name {
      @include skeleton-content-item;
      width: 210.416px;
      height: 30px;
    }

    .header-counter {
      @include skeleton-content-item;
      width: 300px;
      height: 30px;
    }
  }

  .card-timeline {
    display: flex;
    gap: 38px;

    .timeline-stage {
      @include skeleton-content-item;
      flex: 1;
      height: 5px;
    }
  }

  .card-summary {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .summary-info {
      display: flex;
      gap: 40px;

      .info-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .info-header {
          @include skeleton-content-item;
          width: 80px;
          height: 18px;
        }

        .info-content {
          @include skeleton-content-item;
          width: 120px;
          height: 30px;
        }
      }
    }

    .summary-actions {
      @include skeleton-content-item;
      width: 190px;
      height: 60px;
    }
  }

  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
