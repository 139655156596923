@use "@scss/global.scss" as *;

.faq {
  margin: 0 auto;
  max-width: $app-container;
  padding: 120px $app-padding-x 130px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .title {
    text-align: center;
    margin-bottom: 48px;
  }

  .add-token-btn {
    font-size: 15px;
  }
}
