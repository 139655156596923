@use "@scss/global.scss" as *;

@mixin skeleton-content-item {
  border-radius: 20px;
  background: $white-1;
}

.staking-list-skeleton {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: $app-container;
  margin: 0 auto;

  .text-item {
    position: relative;
    border-radius: 10px;
    background: #efeef2;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $gradient-8;
      animation: skeleton-loading 1.2s infinite ease-in-out;
    }
  }

  .list-title {
    @extend .text-item;
    width: 260px;
    height: 45px;
  }

  .list-description {
    @extend .text-item;
    margin-top: 10px;
    width: 620px;
    height: 50px;
  }

  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
