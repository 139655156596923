@use "@scss/global.scss" as *;

.unsupported-browser-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 40px;
  width: 650px;
  min-height: 300px;

  @include mq-max($md) {
    width: 100%;
  }
}
