@use "@scss/global.scss" as *;

.switch-network-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 40px 0 0;
  width: 500px;

  .description {
    max-width: 460px;
    text-align: center;
  }

  @include mq-max($md) {
    width: 100%;
  }
}
