@use "@scss/global.scss" as *;

.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: $white-0;
  box-shadow: $box-shadow-2;
  height: 74px;
  z-index: $navbar-z-index;

  &-content {
    margin: 0 auto;
    max-width: $app-container;
    padding: 16px $app-padding-x;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .skey-network-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 4px;

      font-weight: $regular;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.03em;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $blue-0;
      }
    }
  }
}
