@use "@scss/global.scss" as *;

.unavailable-on-mobile-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 40px 0 0;
  width: 500px;
  text-align: center;

  @include mq-max($md) {
    width: 100%;
  }
}
