@use "@scss/global.scss" as *;

.backdrop-wrapper {
  width: 100vw;
  height: 100vh;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  &.open {
    .backdrop {
      animation: show-backdrop 0.2s both;
      -webkit-animation: show-backdrop 0.2s both;
    }
  }

  &.hide {
    .backdrop {
      animation: hide-backdrop 0.2s both;
      -webkit-animation: hide-backdrop 0.2s both;
    }
  }

  .backdrop {
    height: 100%;
    width: 100%;
    transition: background 0.2s ease-in-out;
  }
}
