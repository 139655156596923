@use "@scss/global.scss" as *;

.spinner {
  --circle-size: 33px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-circle {
    width: var(--circle-size);
    height: var(--circle-size);
    animation: spin-left 0.7s linear infinite;
  }

  @keyframes spin-left {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
