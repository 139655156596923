@use "@scss/global.scss" as *;

.subscribe-assigned-card {
  position: relative;
  min-height: 290px;
  max-width: $app-container;
  width: 100%;
  display: flex;
  flex-flow: row;
  border-radius: 20px;
  box-shadow: $box-shadow-4;

  .card-main {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 25px;
    min-height: 100%;
    width: 100%;
    padding: 30px 40px;
    border-radius: 20px;
    background: $white-0;

    .main-triangle {
      position: absolute;
      top: 50%;
      right: 2px;
      width: 35px;
      height: 35px;
      transform: rotate(45deg) translate(0, -50%);
      background: $white-1;
      z-index: -1;
    }

    .card-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .timer-text {
        font-size: 15px;
        font-weight: $regular;
        line-height: normal;
        letter-spacing: 0.15px;
        align-self: flex-start;
        padding-top: 2px;
        white-space: pre-wrap;
      }
    }

    .card-timeline {
    }

    .card-summary {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .staking-info {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px;
      }

      .staking-actions {
        display: flex;
        flex-flow: row;
        gap: 20px;
      }
    }
  }

  .card-rewards {
    position: relative;
    min-width: 216px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-0;

    &::after {
      content: "";
      position: absolute;
      display: flex;
      top: 0;
      left: -40px;
      bottom: 0;
      width: 256px;
      height: 100%;
      border-radius: 20px;
      background: $gradient-0;
      z-index: -2;
    }

    .rewards-content {
      max-width: 170px;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    .rewards-text {
      font-size: 20px;
      font-weight: $bold;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }
}
