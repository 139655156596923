@use "@scss/global.scss" as *;

.countdown-timer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &-time-section {
    display: flex;
    column-gap: 8px;
  }

  &-unit {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .timer-value {
      font-family: "Mazzard";
      font-weight: $bold;
    }

    .timer-label {
      font-weight: $regular;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.15px;
      text-transform: capitalize;

      color: $white-6;
      background: $white-6;
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: $white-6;
      -moz-text-fill-color: $white-6;
    }
  }

  &--large {
    column-gap: 45px;

    .countdown-timer-unit--time {
      min-width: 120px;
    }

    .timer-value {
      font-size: 80px;
      line-height: 95px;
    }
  }

  &--small {
    column-gap: 25px;

    .countdown-timer-unit--time {
      min-width: 46px;
    }

    .timer-value {
      font-size: 44px;
      line-height: normal;
    }
  }

  &--blue {
    color: $primary;
    background: $primary;
    background-image: $gradient-0;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  &--green {
    color: $green-2;
    background: $green-2;
    background-image: $gradient-3;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}
