@use "@scss/global.scss" as *;

.pending-launch-card {
  position: relative;
  min-height: 230px;
  max-width: $app-container;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 32px;
  padding: 30px 40px;
  border-radius: 20px;
  background: $white-0;
  box-shadow: $box-shadow-4;

  .card-badge {
    position: absolute;
    top: -26.3px;
    left: -26.3px;
  }

  .card-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    .timer-text {
      font-size: 15px;
      font-weight: $regular;
      line-height: normal;
      letter-spacing: 0.15px;
      align-self: flex-start;
      padding-top: 2px;
      white-space: pre-wrap;
    }
  }

  .card-main {
    display: flex;
    flex-flow: column;

    .staking-summary {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .staking-info {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px;
      }

      .staking-actions {
        display: flex;
        flex-flow: row;
        gap: 20px;
      }
    }
  }
}
