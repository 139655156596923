@use "@scss/global.scss" as *;

.social-icon-link {
  @include no-select();
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg path {
      fill: $blue-2;
      fill-opacity: 1;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
