@use "@scss/global.scss" as *;

.earn-stage-card {
  position: relative;
  min-height: 290px;
  max-width: $app-container;
  width: 100%;
  padding: 30px 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-radius: 20px;
  background: $white-0;
  box-shadow: $box-shadow-4;

  .card-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .card-timeline {
  }

  .card-summary {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .staking-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 30px;
    }

    .staking-actions {
      display: flex;
      flex-flow: row;
      gap: 20px;
    }
  }
}
