@use "@scss/global.scss" as *;

.staking-timeline {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;

  .timeline-stage {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 8px;

    .stage-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}
