@use "@scss/global.scss" as *;

.timeline {
  padding-top: 40px;
  padding-bottom: 10px;
  max-height: 312px;

  .ball-text {
    flex: 1;
    margin-left: 20px;
    padding: 4px 0px;
    height: 27px;
  }
  .line-text {
    margin: 20px 0px 20px 23px;
  }

  .line {
    margin-left: 3px;
    border-radius: 2px;
    margin-top: -3px;
    width: 4px;
    height: auto;
    min-height: 26.5px;
    max-height: 74px;
    align-self: stretch;
    background: $white-7;

    &-filled {
      background: var(--Linear, linear-gradient(270deg, #3495ff 0.02%, #5c44ec 97.16%));
    }
  }

  .ball {
    width: 10px;
    height: 10px;
    align-self: flex-end;
    margin-bottom: 8px;
    border-radius: 5px;
    background: var(--Linear, linear-gradient(270deg, #3495ff 0.02%, #5c44ec 97.16%));

    &-not-filled {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin-left: 2px;
      margin-top: 2px;
      background-color: #fff;
    }
  }
}
