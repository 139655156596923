@use "@scss/global.scss" as *;

.staking-hero {
  position: relative;
  // min-height: 600px;

  &-background {
    position: absolute;
    width: 1580px;
    height: auto;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &-content {
    position: relative;
    margin: 0 auto;
    max-width: $app-container;
    padding: 110px $app-padding-x 0;
    display: flex;
    flex-flow: column nowrap;

    .content-previous {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      justify-content: flex-end;
    }

    .content-main {
      margin-top: 40px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;
      white-space: pre-wrap;

      .title {
        font-family: Mazzard;
        font-size: 44px;
        font-weight: 700;
        line-height: normal;
        width: 50%;
      }
    }
  }
}
