@use "@scss/global.scss" as *;

.input-label {
  margin-top: 44px;
  margin-bottom: 12px;
}

.stake-button-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;

  .connect-metamask-btn {
    max-width: none;
    width: 100%;
  }
}

.staking-modal-input {
  border-radius: 20px;
  border: 1px solid $white-7;
  padding: 20px;

  .input-wrapper {
    background: #f7f7f8;
    border-radius: 8px;
    width: 100%;
    height: 62px;
    padding: 4px 32px;

    .input {
      height: 100%;
      background: #f7f7f8;
      width: 100%;
      font-size: 28px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .percentage-buttons {
    div {
      background: $white-2;
      margin: 1px;
      border: 1px solid $white-2;
      border-radius: 8px;
      cursor: pointer;

      p {
        padding: 4.5px 16px;
        border-radius: 8px;
        background: #fff;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.56px;
      }
    }
    div:focus {
      margin: 0px;
      background: $gradient-0;
      border: none;
      padding: 2px;
    }
  }
}

.transaction-status {
  padding: 20px 0px;
  text-align: center;
}

.text-wrapper {
  margin: 0px 70px;
}

.loading-animation {
  display: "flex";
  align-items: "center";
  width: 48px;
  height: 48px;
}

.transaction-status-title {
  margin: 40px 0px;
}

.transaction-status-error-description {
  margin: 40px 70px 60px 70px;
  width: 320px;
}

.transaction-status-success-description {
  margin: 40px 52px 60px 52px;
  width: 356px;
}
