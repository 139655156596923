@use "@scss/global.scss" as *;

.not-found {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 80vh;

  &-description {
    max-width: 500px;
    text-align: center;
  }
}
