@use "@scss/global.scss" as *;

.telegram-channels {
  .dropdown-target {
    svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      svg path {
        fill: $blue-2;
      }
    }
  }

  .dropdown-menu {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;
    padding: 23px 32px;
    border-radius: 10px;
    background: $gradient-2;
  }
}
