@use "./src/scss/global.scss" as *;

.accordion-summary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-bottom: 16px;
}
