@use "@scss/global.scss" as *;

.staking-progress {
  --progress-width: 0%;

  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  &-bar {
    position: relative;
    z-index: 0;

    .line-inactive {
      width: 100%;
      height: 6px;
      background: $white-7;
      border-radius: 100px;
      position: absolute;
      z-index: 0;
    }

    .current-point {
      position: absolute;
      top: -30px;
      left: var(--progress-width);
      transform: translateX(-50%);
    }

    .line-active {
      height: 6px;
      width: var(--progress-width);
      background: $gradient-0;
      border-radius: 100px;
      position: absolute;
      max-width: 100%;
      z-index: 2;
      animation: load-progress 2s normal forwards;
    }
  }

  &-completed {
    .line-active {
      background: $gradient-3 !important;
    }
  }

  &-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
