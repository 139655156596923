@use "@scss/global.scss" as *;

.collapsible-list {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-size: 32px;
      letter-spacing: -0.64px;
    }

    .description {
      max-width: 620px;
    }

    .collapse-btn {
      transition: all 0.2s ease-in-out;
    }

    .collapse-btn--expanded {
      transform: rotate(180deg);
    }
  }

  &-content {
    width: 100%;
    will-change: height;
  }
}
