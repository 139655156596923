@use "@scss/global.scss" as *;

.subscribe-stage-card {
  position: relative;
  min-height: 290px;
  max-width: $app-container;
  width: 100%;
  display: flex;
  flex-flow: row;
  border-radius: 20px;
  padding: 4px;
  box-shadow: $box-shadow-4;
  background: $gradient-0;

  .card-badge {
    position: absolute;
    top: -26.3px;
    left: -26.3px;
    z-index: 10;
  }

  .card-main {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 25px;
    min-height: 100%;
    width: 100%;
    padding: 30px 40px;
    border-radius: 16px;
    background: $white-0;
    box-shadow: $box-shadow-5;

    .card-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .timer-text {
        font-size: 15px;
        font-weight: $regular;
        line-height: normal;
        letter-spacing: 0.15px;
        align-self: flex-start;
        padding-top: 2px;
        white-space: pre-wrap;
      }
    }

    .card-timeline {
    }

    .card-summary {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .staking-info {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px;
      }

      .staking-actions {
        display: flex;
        flex-flow: row;
        gap: 20px;
      }
    }
  }
}
