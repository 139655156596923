@use "./src/scss/global.scss" as *;

.accordion-tab {
  position: relative;
  width: 100%;
  height: max-content;
  padding-bottom: 24px;

  &-divider {
    opacity: 0.2;
    height: 1px;
    background-color: $black-1;
    transition: background-color, opacity 0.2s ease-in-out;

    &-hover {
      background-color: $primary;
      opacity: 1;
    }

    &-active {
      background-color: $black-1;
      opacity: 1;
    }
  }
}
