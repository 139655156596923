@use "@scss/global.scss" as *;

.staking-dashboard {
  margin: 0 auto;
  max-width: $app-container;
  padding: 100px $app-padding-x 0;
  display: flex;
  flex-flow: column nowrap;
  min-height: 80vh;

  &-header {
    padding-bottom: 36px;
  }

  &-main {
    display: flex;
    flex-flow: column nowrap;
    gap: 42px;
  }
}
