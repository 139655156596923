@use "@scss/global.scss" as *;

.modal {
  position: relative;
  height: max-content;
  max-height: 95vh;
  max-width: calc(100% - 2 * $app-padding-x);
  overflow-y: auto;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: $white-0;
  box-shadow: $box-shadow-3;
  padding: 40px;
  border-radius: 20px;
  animation: open 0.2s ease-out forwards;

  &-close-box {
    position: absolute;
    top: 20px;
    right: 20px;

    .modal-close-btn {
      svg path {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        svg path {
          stroke: $blue-0;
        }
      }
    }
  }

  @include mq-max($md) {
    padding: 40px 16px;
    width: 100% !important;
  }

  @keyframes open {
    0% {
      visibility: visible;
      opacity: 0;
      // transform: translate(-50%, calc(-50% - 10px));
    }
    100% {
      visibility: visible;
      opacity: 1;
      // transform: translate(-50%, -50%);
    }
  }
}
