@use "./src/scss/global.scss" as *;

.checkbox {
  display: inline-flex;
  width: auto;
  column-gap: 8px;
  cursor: pointer;
  text-align: justify;

  &-native {
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &-box {
    width: 20px;
    height: 20px;
    border: 1px solid $white-4;
    border-radius: 2px;
    background: $white-1;
    flex-shrink: 0;
  }

  &-native:checked + .checkbox-box {
    background: url("../../../../public/images/icons/checkbox-mark.svg") center no-repeat;
    background-color: $primary;
    border: none;
    width: 20px;
    height: 20px;
  }

  &-label {
    @include p1();
    line-height: 22px;

    > * {
      line-height: 22px;
    }
  }

  &-error {
    @include p0();
    color: $danger;
    margin-left: 30px;
  }

  &--disabled {
    cursor: default;
  }

  &-native:disabled:not(.checkbox-native:checked) + .checkbox-box {
    background-color: $white-7;
    opacity: 0.5;
  }

  &-native:disabled:checked + .checkbox-box {
    opacity: 0.5;
  }
}
