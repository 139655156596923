@use "@scss/global.scss" as *;

.details-group {
  margin-top: 40px;
  height: 62px;
}

.stack {
  width: 50%;
}

.stack-text {
  margin-top: 10px;
  font-size: 18px;
}
