@use "@scss/global.scss" as *;

.days-progress-bar {
  --progress-width: 0%;

  position: relative;
  display: flex;
  align-items: center;
  height: 4px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 100px;
  background: $white-7;

  .line-active {
    height: 6px;
    width: var(--progress-width);
    border-radius: 100px;

    &-with-anim {
      animation: load-progress 2s normal forwards;
    }

    &-subscribe {
      background: $gradient-0;
    }
    &-earn {
      background: $green-0;
    }
    &-claim {
      background: $green-1;
    }
  }

  @keyframes load-progress {
    0% {
      width: 0;
    }
    100% {
      width: var(--progress-width);
    }
  }
}
