@use "@scss/global.scss" as *;

.metamask-modal-info {
  padding: 20px 0px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  width: 460px;
  text-align: center;

  .title {
    margin: 40px 0px;
  }

  .description {
    margin-bottom: 40px;
  }
}
