@use "@scss/global.scss" as *;

.tooltip {
  &-wrapper {
    width: max-content;
  }

  &-tip {
    position: relative;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    background: $black-1;
    color: $white-0;
    font-weight: $regular;
    font-size: 13px;
    line-height: 1.5;
    animation: appear 0.2s ease-out forwards;
    z-index: 300;
    word-wrap: break-word;

    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      border: 10px solid transparent;
      pointer-events: none;
    }
  }

  &-tip[data-popper-placement^="top"] {
    &::before {
      left: 50%;
      top: 100%;
      margin-left: -10px;
      border-top-color: $black-1;
    }
  }

  &-tip[data-popper-placement^="right"] {
    &::before {
      top: 50%;
      right: 100%;
      margin-top: -10px;
      border-right-color: $black-1;
    }
  }

  &-tip[data-popper-placement^="bottom"] {
    &::before {
      left: 50%;
      bottom: 100%;
      margin-left: -10px;
      border-bottom-color: $black-1;
    }
  }

  &-tip[data-popper-placement^="left"] {
    &::before {
      top: 50%;
      left: 100%;
      margin-top: -10px;
      border-left-color: $black-1;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
