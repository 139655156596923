@use "@scss/global.scss" as *;

.wallet-address {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: max-content;
  height: max-content;
  padding: 5px 5px 5px 14px;
  background: $white-7;
  border-radius: 33px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  color: $black-1;
  font-size: 12px;
  font-weight: $medium;
  line-height: 170%;
  letter-spacing: 0.24px;

  &:hover {
    color: $blue-0;
  }
}
