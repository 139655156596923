@use "@scss/global.scss" as *;

.staking-list {
  padding: 240px $app-padding-x 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 100%;

  .error-title {
    margin-bottom: 40px;
  }

  .no-upcoming-stakings {
    margin: 0 0 100px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 20px;
    max-width: 650px;
    text-align: center;
  }

  .list-header {
    margin: 0 auto;
    max-width: $app-container;
    width: 100%;
  }

  .list-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 40px;
    width: 100%;
    padding: 40px 0 80px;
  }
}
