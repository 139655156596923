@keyframes show-backdrop {
  0% {
    background: rgba(#120e2f, 0);
  }
  100% {
    background: rgba(#120e2f, 0.5);
  }
}

@keyframes hide-backdrop {
  0% {
    background: rgba(#120e2f, 0.5);
  }
  100% {
    background: rgba(#120e2f, 0);
  }
}
