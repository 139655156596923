@use "@scss/global.scss" as *;

.button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: Oxygen;
  font-size: 18px;
  font-weight: $regular;
  line-height: 150%;
  letter-spacing: 0.18px;

  width: max-content;
  height: 59px;
  transition: all 0.3s ease-in-out;

  &-primary {
    color: $white-0;
    background: $gradient-0;
    padding: 16px 32px;
    border-radius: 8px;

    &:hover {
      box-shadow: $box-shadow-0;
    }
  }

  &-secondary {
    color: $blue-0;
    background: $white-0;
    padding: 16px 32px;
    border-radius: 10px;
    border: 1px solid $white-2;

    &:hover {
      color: $black-1;
      box-shadow: $box-shadow-1;
    }
  }

  &-trietary {
    color: $blue-0;
    background: transparent;
    padding: 0px 10px;

    &:hover {
      color: $black-1;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.full-width {
    width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
