@use "./variables.scss" as *;
@use "./typography-mixins.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts//MazzardH-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts/MazzardH-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

html {
  color: $black-1;
  background-color: $white-1;
  font-size: 15px;
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-width: $lg;
}
// Font's classes ************************************************************

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

p,
.p0 {
  @include p0;
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}

.p3 {
  @include p3;
}

// Color's classes ***********************************************************

.primary {
  color: $primary;
}
.primary-BG {
  background-color: $primary;
}
.primary-dark {
  color: $primary-dark;
}
.primary-dark-bg {
  background-color: $primary-dark;
}
.primary-dark-hover {
  color: $primary-dark-hover;
}
.primary-dark-hover-bg {
  background-color: $primary-dark-hover;
}

.blue-0 {
  color: $blue-0;
}
.blue-0-bg {
  background-color: $blue-0;
}
.blue-1 {
  color: $blue-1;
}
.blue-1-bg {
  background-color: $blue-1;
}
.blue-2 {
  color: $blue-2;
}
.blue-2-bg {
  background-color: $blue-2;
}
.blue-3 {
  color: $blue-3;
}
.blue-3-bg {
  background-color: $blue-3;
}

.white-0 {
  color: $white-0;
}
.white-0-bg {
  background-color: $white-0;
}
.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}
.white-2 {
  color: $white-2;
}
.white-2-bg {
  background-color: $white-2;
}
.white-3 {
  color: $white-3;
}
.white-3-bg {
  background-color: $white-3;
}
.white-4 {
  color: $white-4;
}
.white-4-bg {
  background-color: $white-4;
}
.white-5 {
  color: $white-5;
}
.white-5-bg {
  background-color: $white-5;
}
.white-6 {
  color: $white-6;
}
.white-6-bg {
  background-color: $white-6;
}
.white7 {
  color: $white-7;
}
.white7-bg {
  background-color: $white-7;
}
.white-8 {
  color: $white-8;
}
.white-8-bg {
  background-color: $white-8;
}

.black-0 {
  color: $black-0;
}
.black-0-bg {
  background-color: $black-0;
}
.black-1 {
  color: $black-1;
}
.black-1-bg {
  background-color: $black-1;
}
.black-2 {
  color: $black-2;
}
.black-2-bg {
  background-color: $black-2;
}

.green-0 {
  color: $green-0;
}
.green-0-bg {
  background-color: $green-0;
}
.green-1 {
  color: $green-1;
}
.green-1-bg {
  background-color: $green-1;
}
.green-2 {
  color: $green-2;
}
.green-2-bg {
  background-color: $green-2;
}

.success {
  color: $success;
}
.success-bg {
  background-color: $success;
}
.danger {
  color: $danger;
}
.danger-bg {
  background-color: $danger;
}
.warning {
  color: $warning;
}
.warning-bg {
  background-color: $warning;
}
.info {
  color: $info;
}
.info-bg {
  background-color: $info;
}

.skey-gradient-text {
  color: $primary;
  background: $primary;
  background-image: $gradient-0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

// Other's classes ***********************************************************

.custom-scrollbar-0 {
  @include custom-scrollbar-0();
}
