@use "@scss/global.scss" as *;

.withdraw-info {
  height: 96px;
  border: 1px solid $white-7;
  min-width: 320px;
  border-radius: 20px;
  padding: 20px;
  background: #f7f7f8;

  .staked-amount {
    font-size: 20px;
  }
}

.withdraw-info-margin {
  margin-top: 43px;
}

.button-wrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
}
