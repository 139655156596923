@use "@scss/global.scss" as *;

.border-top {
  margin-top: 43px;
  border-radius: 20px 20px 0px 0px;
}

.claim-info {
  background: linear-gradient(270deg, #36d125 0%, #18a409 100%);
  color: $white-0;
  padding: 20px;
  border: 1px solid #36d125;
  border-radius: 0px 0px 20px 20px;

  .claim-amount {
    font-size: 20px;
  }
}

.button-wrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
}
