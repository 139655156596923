@use "./mixins.scss" as *;

$primary: #5c44ec;
$primary-dark: #7f70db;
$primary-dark-hover: #998de2;

$blue-0: #5c44ec;
$blue-1: #7f70db;
$blue-2: #998de2;
$blue-3: #9381ff;

$white-0: #ffffff;
$white-1: #fafafc;
$white-2: #eeeef1;
$white-3: #f3f3f4;
$white-4: #b8b7c1;
$white-5: #d0cfd5;
$white-6: #a09fac;
$white-7: #e8e7eb;
$white-8: #f5f5f5;

$black-0: #000000;
$black-1: #120e2f;
$black-2: #0b091c;

$green-0: #3aa12f;
$green-1: #36d125;
$green-2: #18a409;

$success: #22b814;
$danger: #ff6b6b;
$warning: #ffbb00;
$info: #18afff;

// GRADIENT'S
$gradient-0: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
$gradient-1: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 43.38%, #bb5dee 97.16%);
$gradient-2: linear-gradient(159.1deg, rgba(255, 255, 255, 0.1) -0.09%, rgba(255, 255, 255, 0) 171.38%), #120e2f;
$gradient-3: linear-gradient(270deg, #36d125 22.06%, #18a409 86.9%);
$gradient-4: linear-gradient(270deg, #36d125 0%, #18a409 100%);
$gradient-5: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
$gradient-6: linear-gradient(0deg, rgba(232, 231, 235, 0.4) 0%, rgba(232, 231, 235, 0.4) 100%), #fff;
$gradient-7: linear-gradient(270deg, #d12559 0%, #a40937 100%);
$gradient-8: linear-gradient(90deg, rgba(222, 221, 224, 0) 0%, #dedde0 48%, rgba(222, 221, 224, 0) 100%);

// SHADOW'S
$box-shadow-0: 9.773px 19.71px 19px 0px rgba(0, 0, 0, 0.07), 4.948px 9.978px 8.283px 0px rgba(0, 0, 0, 0.05),
  1.955px 3.942px 3.088px 0px rgba(0, 0, 0, 0.04), 0.428px 0.862px 1.098px 0px rgba(0, 0, 0, 0.02);
$box-shadow-1: 8px 8px 40px 0px rgba(18, 14, 47, 0.02), 0px 46.809px 70.213px 0px rgba(0, 0, 0, 0.04),
  -4px 11.702px 23.404px 0px rgba(0, 0, 0, 0.02), 0px 0px 2.926px 0px rgba(0, 0, 0, 0.04);
$box-shadow-2: 0px 12px 17px 0px rgba(0, 0, 0, 0.02), 0px 4.622px 5.415px 0px rgba(0, 0, 0, 0.01),
  0px 0.978px 1.385px 0px rgba(0, 0, 0, 0.01);
$box-shadow-3: 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.06), 0px 11.7021px 23.4043px rgba(0, 0, 0, 0.04),
  0px 0px 2.92553px rgba(0, 0, 0, 0.04);
$box-shadow-4: 0px 46.809px 70.213px 0px rgba(0, 0, 0, 0.06), 0px 11.702px 23.404px 0px rgba(0, 0, 0, 0.04),
  0px 0px 2.926px 0px rgba(0, 0, 0, 0.04);
$box-shadow-5: 0px 0px 50px 0px rgba(52, 149, 255, 0.3) inset;

// BREAKPOINTS
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

$breakpoints: (
  xs: "$xs",
  sm: "$sm",
  md: "$md",
  lg: "$lg",
  xl: "$xl",
  xxl: "$xxl"
);

// SIZES
$size-variants: (
  0: 0,
  1: 2px,
  2: 4px,
  3: 8px,
  4: 16px,
  5: 24px,
  6: 32px,
  7: 40px,
  8: 48px,
  9: 64px,
  10: 80px,
  11: 96px,
  12: 160px
);

// FONT WEIGHT'S
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;
$extra-black: 950;

//POSITION VARIANTS
$position-variants: (
  start: "flex-start",
  center: "center",
  end: "flex-end"
);

// APP SIZES
$mobile: $md;
$app-padding-x: 16px;
$app-container: 1300px + 2 * $app-padding-x;

$navbar-h: 60px;
$sidebar-w: 80px;

$navbar-z-index: 300;
