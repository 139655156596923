@use "@scss/global.scss" as *;

.footer {
  color: $white-1;

  &-main {
    background: $black-1;
    padding: 40px 0;

    .main-content {
      margin: 0 auto;
      max-width: $app-container;
      padding: 0px $app-padding-x;
      display: flex;
      flex-flow: column nowrap;
    }

    .main-row {
      margin-top: 32px;
      display: flex;
      flex-flow: row wrap;
      gap: 28px;

      .social-media {
        display: flex;
        flex-flow: column;
        max-width: 415px;
        width: 100%;

        .social-list {
          padding-top: 16px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 16px;
        }
      }

      .legal-disclaimer,
      .contact,
      .project-reporting {
        display: flex;
        flex-flow: column;
        gap: 24px;
        flex: 1;
      }

      .legal-disclaimer {
        max-width: 300px;
      }

      .contact {
        max-width: 195px;
      }

      .project-reporting {
        max-width: 305px;
      }

      @include mq-max($lg) {
        .social-media {
          max-width: 100%;
        }
      }

      @include mq-max($md) {
        .social-media,
        .legal-disclaimer,
        .contact,
        .project-reporting {
          max-width: 100%;
          width: 100%;
          flex: unset;
        }
      }
    }
  }

  &-copyright {
    padding: 21px 0;
    background: $black-2;

    .copyright-content {
      margin: 0 auto;
      max-width: $app-container;
      padding: 0px $app-padding-x;
      display: flex;
      flex-flow: row nowrap;
    }
  }
}
