@use "@scss/global.scss" as *;

.link {
  @include no-select();
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease-in-out;
  width: max-content;

  font-size: 15px;
  font-weight: $regular;
  line-height: 170%;
  letter-spacing: 0.24px;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &-primary {
    color: $blue-1;

    &:hover {
      color: $blue-2;

      svg path {
        stroke: $blue-2;
      }
    }
  }

  &-secondary {
    color: $white-4;

    &:hover {
      color: $white-5;

      svg path {
        stroke: $white-5;
      }
    }
  }

  &-tertiary {
    color: $white-0;

    &:hover {
      color: $white-5;

      svg path {
        stroke: $white-5;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
